import { asyncComponent, AsyncRouteProps } from '@jaredpalmer/after';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { match as Match } from 'react-router-dom';
import Placeholder from './AppComponentLoader';
import { SupportedLocale } from './i18n/types';
import { customerProfileNavigationItems, showContactPage, showDownloadDocuments } from './config';

/**
 * Standard routes builder...
 * @param locale
 * @param pathe
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderDefaultdUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Record<string, any> | undefined,
): string {
  const { pathParams, queryParams, otherParamsExists } = routesBuilderSplitInputParams(
    locale,
    otherParamsToQs,
    path,
    inputParams || {},
  );

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}

export function pathToRouteUrlType(path: string): RouteUrlType | null {
  for (const key in routesDefinition) {
    const def = routesDefinition[key as RouteUrlType];
    if (def && typeof def.path !== 'object') continue;

    const paths = Object.values(def?.path || {}) as string[];
    if (paths.includes(path)) return key as RouteUrlType;
  }

  return null;
}

export const routesDefinition = {
  catalogue: {
    path: {
      cs: '/:locale/catalogue',
      en: '/:locale/catalogue',
      hu: '/:locale/catalogue',
      de: '/:locale/catalogue',
      sk: '/:locale/catalogue',
      hr: '/:locale/catalogue',
      sl: '/:locale/catalogue',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  categories: {
    path: {
      cs: '/:locale/catalogue/:categories*',
      en: '/:locale/catalogue/:categories*',
      hu: '/:locale/catalogue/:categories*',
      de: '/:locale/catalogue/:categories*',
      sk: '/:locale/catalogue/:categories*',
      hr: '/:locale/catalogue/:categories*',
      sl: '/:locale/catalogue/:categories*',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  special: {
    path: {
      cs: '/:locale/special/:categories*',
      en: '/:locale/special/:categories*',
      hu: '/:locale/specialis/:categories*',
      de: '/:locale/spezial/:categories*',
      sk: '/:locale/special/:categories*',
      hr: '/:locale/special/:categories*',
      sl: '/:locale/special/:categories*',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  product: {
    path: {
      cs: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      en: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      hu: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      de: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      sk: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      hr: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      sl: '/:locale/:sn([^/]+)-product-:id([^/]+)',
    },
    component: asyncComponent({
      loader: () => import('./pages/ProductDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  bulkPurchase: {
    path: {
      cs: '/:locale/hromadny-nakup',
      en: '/:locale/bulk-purchase',
      hu: '/:locale/nagybani-beszerzese',
      de: '/:locale/masseneinkauf',
      sk: '/:locale/hromadny-nakup',
      hr: '/:locale/hromadny-nakup',
      sl: '/:locale/hromadny-nakup',
    },
    component: asyncComponent({
      loader: () => import('./pages/BulkPurchasePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(showDownloadDocuments
    ? {
        downloadDocuments: {
          path: {
            cs: '/:locale/dokumenty-ke-stazeni',
            en: '/:locale/documents-to-download',
            hu: '/:locale/dokumentumok-letoltese',
            de: '/:locale/dokumente-zum-download',
            sk: '/:locale/dokumenty-na-stiahnutie',
            hr: '/:locale/dokumenty-na-stiahnutie',
            sl: '/:locale/dokumenty-na-stiahnutie',
          },
          component: asyncComponent({
            loader: () => import('./pages/DownloadDocumentsPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  cartSummary: {
    path: {
      cs: '/:locale/cart/summary',
      en: '/:locale/cart/summary',
      hu: '/:locale/cart/osszefoglalo',
      de: '/:locale/cart/zusammenfassung',
      sk: '/:locale/cart/zhrnutie',
      hr: '/:locale/cart/zhrnutie',
      sl: '/:locale/cart/zhrnutie',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartSummaryPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderThankYou: {
    path: {
      cs: '/:locale/dekujeme-za-objednavku',
      en: '/:locale/thanks',
      hu: '/:locale/koszonjuk',
      de: '/:locale/danke',
      sk: '/:locale/vdaka-za-objednavku',
      hr: '/:locale/vdaka-za-objednavku',
      sl: '/:locale/vdaka-za-objednavku',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderThankYouPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderSentToApproval: {
    path: {
      cs: '/:locale/objednavka-odeslana-ke-schvaleni',
      en: '/:locale/order-sent-to-approval',
      hu: '/:locale/rendeles-elkuldve-jovahagyasra',
      de: '/:locale/bestellung-gesendet-zur-genehmigung',
      sk: '/:locale/objednavka-odoslana-na-schvalenie',
      hr: '/:locale/objednavka-odoslana-na-schvalenie',
      sl: '/:locale/objednavka-odoslana-na-schvalenie',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderSentToApprovalPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(customerProfileNavigationItems.includes('cartsApproval')
    ? {
        cartsApproval: {
          path: {
            cs: '/:locale/profil/schvaleni-objednavek',
            en: '/:locale/profile/orders-approval',
            hu: '/:locale/profil/megrendelesek-jovahagyas',
            de: '/:locale/profil/auftragsbestatigung',
            sk: '/:locale/profil/schvalenie-objednavok',
            hr: '/:locale/profil/schvalenie-objednavok',
            sl: '/:locale/profil/schvalenie-objednavok',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CartsApprovalPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  cartApproval: {
    path: {
      cs: '/:locale/profil/schvaleni-objednavek/:id',
      en: '/:locale/profile/orders-approval/:id',
      hu: '/:locale/profil/megrendelesek-jovahagyas/:id',
      de: '/:locale/profil/auftragsbestatigung/:id',
      sk: '/:locale/profil/schvalenie-objednavok/:id',
      hr: '/:locale/profil/schvalenie-objednavok/:id',
      sl: '/:locale/profil/schvalenie-objednavok/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartApprovalPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(customerProfileNavigationItems.includes('customerProfile')
    ? {
        customerProfile: {
          path: {
            cs: '/:locale/profil',
            en: '/:locale/profile',
            hu: '/:locale/profil',
            de: '/:locale/profil',
            sk: '/:locale/profil',
            hr: '/:locale/profil',
            sl: '/:locale/profil',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfilePage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  ...(customerProfileNavigationItems.includes('customerProfileOrders')
    ? {
        customerProfileOrders: {
          path: {
            cs: '/:locale/profil/objednavky',
            en: '/:locale/profile/orders',
            hu: '/:locale/profil/parancsokat',
            de: '/:locale/profil/auftrage',
            sk: '/:locale/profil/objednavky',
            hr: '/:locale/profil/objednavky',
            sl: '/:locale/profil/objednavky',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileOrdersPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  customerProfileOrderDetail: {
    path: {
      cs: '/:locale/profil/objednavky/:id',
      en: '/:locale/profile/orders/:id',
      hu: '/:locale/profil/parancsokat/:id',
      de: '/:locale/profil/auftrage/:id',
      sk: '/:locale/profil/objednavky/:id',
      hr: '/:locale/profil/objednavky/:id',
      sl: '/:locale/profil/objednavky/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileOrderDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(customerProfileNavigationItems.includes('customerProfileInvoices')
    ? {
        customerProfileInvoices: {
          path: {
            cs: '/:locale/profil/faktury',
            en: '/:locale/profile/invoices',
            hu: '/:locale/profil/szamlakat',
            de: '/:locale/profil/rechnungen',
            sk: '/:locale/profil/faktury',
            hr: '/:locale/profil/faktury',
            sl: '/:locale/profil/faktury',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileInvoicesPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  customerProfileInvoiceDetail: {
    path: {
      cs: '/:locale/profil/faktury/:id',
      en: '/:locale/profile/invoices/:id',
      hu: '/:locale/profil/szamlakat/:id',
      de: '/:locale/profil/rechnungen/:id',
      sk: '/:locale/profil/faktury/:id',
      hr: '/:locale/profil/faktury/:id',
      sl: '/:locale/profil/faktury/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileInvoiceDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(customerProfileNavigationItems.includes('customerProfileCreditNotes')
    ? {
        customerProfileCreditNotes: {
          path: {
            cs: '/:locale/profil/dobropisy',
            en: '/:locale/profile/credit-notes',
            hu: '/:locale/profil/hitel-jegyzetek',
            de: '/:locale/profil/gutschriften',
            sk: '/:locale/profil/dobropisy',
            hr: '/:locale/profil/dobropisy',
            sl: '/:locale/profil/dobropisy',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileCreditNotesPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  ...(customerProfileNavigationItems.includes('customerProfileInvoicesAndCreditNotes')
    ? {
        customerProfileInvoicesAndCreditNotes: {
          path: {
            cs: '/:locale/profil/faktury-a-dobropisy',
            en: '/:locale/profile/invoices-and-credit-notes',
            hu: '/:locale/profil/szamlak-es-jovairasok',
            de: '/:locale/profil/rechnungen-und-gutschriften',
            sk: '/:locale/profil/faktury-a-dobropisy',
            hr: '/:locale/profil/faktury-a-dobropisy',
            sl: '/:locale/profil/faktury-a-dobropisy',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileInvoicesAndCreditNotesPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  customerProfileCreditNoteDetail: {
    path: {
      cs: '/:locale/profil/dobropisy/:id',
      en: '/:locale/profile/credit-notes/:id',
      hu: '/:locale/profil/hitel-jegyzetek/:id',
      de: '/:locale/profil/gutschriften/:id',
      sk: '/:locale/profil/dobropisy/:id',
      hr: '/:locale/profil/dobropisy/:id',
      sl: '/:locale/profil/dobropisy/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileCreditNoteDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(customerProfileNavigationItems.includes('customerProfileDocuments')
    ? {
        customerProfileDocuments: {
          path: {
            cs: '/:locale/profil/dokumenty',
            en: '/:locale/profile/documents',
            hu: '/:locale/profil/dokumentumokat',
            de: '/:locale/profil/unterlagen',
            sk: '/:locale/profil/dokumenty',
            hr: '/:locale/profil/dokumenty',
            sl: '/:locale/profil/dokumenty',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileDocumentsPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  ...(customerProfileNavigationItems.includes('customerProfileDeliveryNotes')
    ? {
        customerProfileDeliveryNotes: {
          path: {
            cs: '/:locale/profil/dodaci-listy',
            en: '/:locale/profile/delivery-notes',
            hu: '/:locale/profil/szallitolevelek',
            de: '/:locale/profil/lieferscheine',
            sk: '/:locale/profil/dodaci-listy',
            hr: '/:locale/profil/dodaci-listy',
            sl: '/:locale/profil/dodaci-listy',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileDeliveryNotesPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  ...(customerProfileNavigationItems.includes('customerProfileComplaints')
    ? {
        customerProfileComplaints: {
          path: {
            cs: '/:locale/profil/reklamace',
            en: '/:locale/profile/complaints',
            hu: '/:locale/profil/complaints',
            de: '/:locale/profil/complaints',
            sk: '/:locale/profil/reklamace',
            hr: '/:locale/profil/complaints',
            sl: '/:locale/profil/complaints',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileComplaintsPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  ...(customerProfileNavigationItems.includes('customerProfileShoppingLists')
    ? {
        customerProfileShoppingLists: {
          path: {
            cs: '/:locale/profil/nakupni-seznamy',
            en: '/:locale/profile/shopping-lists',
            hu: '/:locale/profil/bevasarlo-listak',
            de: '/:locale/profil/einkaufslisten',
            sk: '/:locale/profil/nakupne-zoznamy',
            hr: '/:locale/profil/nakupne-zoznamy',
            sl: '/:locale/profil/nakupne-zoznamy',
          },
          exact: true,
          component: asyncComponent({
            loader: () => import('./pages/CustomerProfileShoppingListsPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  homePage: {
    path: {
      cs: '/:locale/domovska-stranka',
      en: '/:locale/home',
      hu: '/:locale/kezdolap',
      de: '/:locale/startseite',
      sk: '/:locale/domovskej-stranke',
      hr: '/:locale/domovskej-stranke',
      sl: '/:locale/domovskej-stranke',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/HomePageNew'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  staticPage: {
    path: '/:locale/-/:group',
    component: asyncComponent({
      loader: () => import('./pages/StaticPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  news: {
    path: {
      cs: '/:locale/aktuality',
      en: '/:locale/news',
      hu: '/:locale/hirek',
      de: '/:locale/nachricht',
      sk: '/:locale/spravy',
      hr: '/:locale/spravy',
      sl: '/:locale/spravy',
    },
    component: asyncComponent({
      loader: () => import('./pages/NewsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  career: {
    path: {
      cs: '/:locale/kariera',
      en: '/:locale/career',
      hu: '/:locale/karrier',
      de: '/:locale/karriere',
      sk: '/:locale/kariera',
      hr: '/:locale/kariera',
      sl: '/:locale/kariera',
    },
    component: asyncComponent({
      loader: () => import('./pages/CareerPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  ...(showContactPage
    ? {
        contacts: {
          path: {
            cs: '/:locale/kontakty',
            en: '/:locale/contacts',
            hu: '/:locale/kapcsolatokat',
            de: '/:locale/kontakte',
            sk: '/:locale/kontakty',
            hr: '/:locale/kontakty',
            sl: '/:locale/kontakty',
          },
          component: asyncComponent({
            loader: () => import('./pages/ContactsPage'),
            Placeholder,
          }),
          urlBuilder: routesBuilderDefaultdUrlBuilder,
        },
      }
    : {}),
  downloads: {
    path: {
      cs: '/:locale/ke-stazeni',
      en: '/:locale/downloads',
      hu: '/:locale/letoltesek',
      de: '/:locale/downloads',
      sk: '/:locale/k-stiahnutiu',
      hr: '/:locale/k-stiahnutiu',
      sl: '/:locale/k-stiahnutiu',
    },
    component: asyncComponent({
      loader: () => import('./pages/DownloadsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  login: {
    path: {
      cs: '/:locale/prihlaseni',
      en: '/:locale/login',
      hu: '/:locale/Belépés',
      de: '/:locale/login',
      sk: '/:locale/prihlasenie',
      hr: '/:locale/prihlasenie',
      sl: '/:locale/prihlasenie',
    },
    component: asyncComponent({
      loader: () => import('./pages/LoginPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  logout: {
    path: {
      cs: '/:locale/odhlaseni',
      en: '/:locale/logout',
      hu: '/:locale/kijelentkezes',
      de: '/:locale/ausloggen',
      sk: '/:locale/odhlasenie',
      hr: '/:locale/odhlasenie',
      sl: '/:locale/odhlasenie',
    },
    component: asyncComponent({
      loader: () => import('./pages/LogoutPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  passwordRenewal: {
    path: {
      cs: '/:locale/zmena-hesla',
      en: '/:locale/change-password',
      hu: '/:locale/jelszo-modositasa',
      de: '/:locale/kennwort-ändern',
      sk: '/:locale/zmena-hesla',
      hr: '/:locale/zmena-hesla',
      sl: '/:locale/zmena-hesla',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/PasswordRenewal'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderDisapprovalSending: {
    path: {
      cs: '/:locale/objednavka-zrusena',
      en: '/:locale/order-cancelled',
      hu: '/:locale/rendeles-torolve',
      de: '/:locale/bestellung-storniert',
      sk: '/:locale/objednavka-zrusena',
      hr: '/:locale/objednavka-zrusena',
      sl: '/:locale/objednavka-zrusena',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderDisapprovalSendingPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
} as const;
/**
 * Returnd builded URL from `type` and params...
 *
 * ```
 * routeUrl('home', {locale, params: { ... } }); // => '/'
 * routeUrl('productDetail', {locale, params: { ... } }); // => '/some-product-name-product-12344'
 * ```
 */

export type RouteUrlType = keyof typeof routesDefinition;

type Sep = `/` | `-` | '_' | '(' | ')' | '[' | ']' | '{' | '}' | '*' | '^';

type Split<
  T extends string,
  Separator extends string,
  Result extends string = '',
> = T extends `${infer Start}${Separator}${infer Rest}`
  ? Split<Rest, Separator, Result | Start>
  : T extends `${infer Last}`
  ? Result | Last
  : never;

type RejectWithSep<T extends string, Separator extends string> = T extends `${Separator}`
  ? never
  : T extends `:${infer Param}`
  ? Param
  : never;

type ExtractParams<T extends string> = RejectWithSep<Split<T, Sep>, Sep>;

export type ParamsDict<T extends string> = Record<ExtractParams<T>, string>;

export function routeUrl(
  type: RouteUrlType,
  opts: {
    locale: SupportedLocale;
    otherParamsToQs?: boolean | string[];
    params: Record<string, string>;
  },
): string {
  const path = routePath(type, opts);
  const def = routesDefinition[type];
  const inputParams = opts.params || {}; // ensure params exists

  return def && def.urlBuilder && typeof def.urlBuilder === 'function'
    ? def.urlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any)
    : routesBuilderDefaultdUrlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any);
}

export function routeIsActive(
  type: keyof typeof routesDefinition,
  opts: {
    customMatcher?: (opts: {
      nodePath: string;
      pathRegexMatches: RegExpExecArray | null;
      routeRegexMatch: boolean;
    }) => boolean;
    locale: SupportedLocale;
    match: Match<any>;
  },
): boolean {
  const nodePath = routePath(type, opts);

  const re = pathToRegexp(opts.match.path, undefined, { strict: opts.match.isExact });
  const pathRegexMatches = re.exec(nodePath);
  const routeRegexMatch = pathRegexMatches !== null;

  return opts.customMatcher ? opts.customMatcher({ nodePath, pathRegexMatches, routeRegexMatch }) : routeRegexMatch;
}

/**
 * Returns a `path` from roteDefinitions by `type` and `opts.locale`.
 *
 * ```
 * routePath('home', { locale }); // => '/'
 * routePath('productDetail', { locale }); // => '/:sn([^/]+)-product-:id([^/]+)'
 * ```
 */
export function routePath(type: keyof typeof routesDefinition, opts: { locale: string }): string {
  const def = routesDefinition[type];
  if (!def) return '/_error';
  return typeof def.path === 'string' ? def.path : def.path[opts.locale];
}

/**
 * Returns `inputParameters` object splitted to two separate
 * @param otherParamsToQs
 * @param path
 * @param inputParams
 */
export function routesBuilderSplitInputParams<P extends Record<string, any>>(
  locale: SupportedLocale,
  otherParamsToQs: boolean | string[] | undefined | null,
  path: string,
  inputParams: P,
): { otherParamsExists: boolean; pathParams: Record<string, any>; queryParams?: Record<string, any> } {
  const [pathParams, queryParams] = !otherParamsToQs
    ? [{ ...inputParams, locale }]
    : ((reg) => {
        const pathParamKeys = reg.keys.map((i) => i.name);
        return Object.entries(inputParams).reduce<[Record<string, any>, Record<string, any>]>(
          (acc, [k, v]) => {
            if (pathParamKeys.includes(k)) {
              return [{ ...acc[0], [k]: v }, acc[1]];
            }

            if (Array.isArray(otherParamsToQs) && !otherParamsToQs.includes(k)) {
              return acc;
            }

            return [acc[0], { ...acc[1], [k]: v }];
          },
          [{ locale }, {}],
        );
      })(pathToRegexp(path));

  const otherParamsExists = !!queryParams && Object.keys(queryParams).length > 0;

  return {
    otherParamsExists,
    pathParams,
    queryParams,
  };
}

/**
 * Returns all router routes definitions...
 */
export function routeAllRoutesToRouter(): AsyncRouteProps[] {
  const extr = ({ urlBuilder, path, ...rest }: RouteDefinition): Omit<AsyncRouteProps, 'path'> => rest;

  return Object.values(routesDefinition).reduce((acc, itm) => {
    if (typeof itm.path === 'string') return [...acc, { path: itm.path, ...extr(itm as any) }] as AsyncRouteProps[];
    return [...acc, ...Object.values(itm.path).map((path) => ({ path, ...extr(itm as any) }))] as AsyncRouteProps[];
  }, [] as AsyncRouteProps[]);
}

const safeAbsoluteUrl = (path: string, url: string): string =>
  `${path.startsWith('/') ? '/' : ''}${url.replace(/^\//, '')}`;

export interface RouteDefinition extends Omit<AsyncRouteProps, 'path'> {
  path: string | Record<SupportedLocale, string>;
  urlBuilder: (
    locale: SupportedLocale,
    path: string,
    otherParamsToQs: boolean | string[] | undefined | null,
    params: any,
  ) => string;
}
