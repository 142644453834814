import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearTwoTone';
import SearchIcon from '@material-ui/icons/SearchTwoTone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LockIcon from '@material-ui/icons/LockTwoTone';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import DialogConfirm from '../../../@sprinx/react-mui-components/DialogConfirm';
import { catalogueGlobalFilterState } from '../../../api/products/catalogue';
import ShoppingCartAppBarAction from '../../../components/ShoppingCartAppBarAction';
import { isAuthenticatedState } from '../../../api/appState';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';
import NavigationLinks from '../../../components/NavigationLinks';
import { projectName } from '../../../config';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import MainNavigationContentItemProfile from './MainNavigationContentItemProfile';
import LanguageMenu from './LanguageMenu';

export interface AppHeaderProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.appBar.background,
      color: theme.palette.appBar.color.contrastText,
      minHeight: '75px',
      display: 'flex',
      justifyContent: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    menuButtonCatalogue: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    navLinksWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '2rem',
      justifyContent: 'center',
      marginLeft: theme.spacing(4),
      marginRight: 'auto',
    },

    iconsWrapper: {
      marginLeft: 'auto',
      minWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 1),
    },
    navBottomBlock: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(1, 1),
    },

    title: {
      fontSize: theme.spacing(1.8),
      color: theme.palette.appBar.color.logo,
      fontWeight: theme.typography.fontWeightBold,
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      textDecoration: 'none',
      maxWidth: '280px',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    mainLogoWrapper: {
      maxHeight: '50px',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    startIcon: {
      cursor: 'pointer',
      position: 'absolute',
      left: 0,
      top: theme.spacing(3),
      backgroundColor: 'none',
      fill: 'black',
      '@media (max-width: 850px)': {
        display: 'none',
      },
    },
    endIcon: {
      position: 'absolute',
      right: 0,
      top: theme.spacing(2),
      backgroundColor: 'inherit',
      '& svg': {
        fill: '#fafafa',
      },
      '@media (max-width: 850px)': {
        position: 'fixed',
        top: '68px',
        right: '5px',
      },
    },
    search: {
      flexGrow: 5,
      position: 'relative',
      maxWidth: '600px',
      marginLeft: theme.spacing(4),
      marginRight: 'auto',
      top: '-5px',
      '@media (max-width:850px)': {
        position: 'relative',
      },
    },
    searchMobile: {
      display: 'none',
      [theme.breakpoints.down('lg')]: {
        backgroundColor: 'inherit',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      },
    },
    input: {
      color: 'black',
      '&:focus': {
        color: 'black',
      },
    },
    ml0: { marginLeft: 0 },
    mainLogo: {
      maxHeight: '50px',
    },
    textField: {
      '& input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
        { display: 'none' },
      '& label + .MuiInput-formControl': {
        paddingLeft: theme.spacing(6),
        color: 'black',
        '&:hover:before': {
          borderBottom: '1px solid black',
        },
        '&:before': {
          borderBottom: '1px solid black',
        },
      },
      '& label': {
        paddingLeft: theme.spacing(6),
        color: 'black!important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px solid black',
      },
      '@media (max-width:850px)': {
        position: 'fixed',
        left: 0,
        top: '60px',
        background: theme.palette.mainNavigation.background,
        paddingRight: theme.spacing(10),
      },
    },
  }),
  { name: 'AppHeader' },
);

export default function AppHeader({ handleDrawerToggle }: AppHeaderProps): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const routeUrlFactory = useRouteUrlFactory();
  const t = useTranslateWithFallback();
  const [logoutConfirm, setLogoutConfirm] = React.useState<boolean>(false);

  // const autoSubmitRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const [initialState, setFilter] = useRecoilState(catalogueGlobalFilterState);
  const [searchFilter, setSearchFilter] = React.useState<string>(initialState.searchText || '');
  const match = useRouteMatch();
  const showFilter = true;
  // match.url.startsWith(routeUrlFactory('catalogue')) || match.url.startsWith(routeUrlFactory('special'));
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const handleClick = React.useCallback(() => setMobileSearchOpen((v) => !v), []);

  const theme = useTheme();
  const isCatalogue = match.url.startsWith(routeUrlFactory('catalogue'));
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  const filterOnChange = (e: any) => {
    const value = e.target.value;
    setSearchFilter(value);
    // TODO: multilang
    // if (value && match.url !== '/catalogue') {
    //   history.push('/catalogue');
    // }
    // if (autoSubmitRef.current) clearTimeout(autoSubmitRef.current);
    // autoSubmitRef.current = setTimeout(() => {
    //   setFilter({ searchText: value });
    // }, 10);
  };

  const handleResetSearchText = useCallback(() => {
    setSearchFilter('');
    setFilter({ searchText: undefined });
  }, [setFilter]);

  const handleSearch = useCallback(() => {
    setFilter({ searchText: searchFilter });

    const nextUrl = new URL(window.location.href);
    nextUrl.searchParams.set('search', searchFilter);
    const nextPath = nextUrl.pathname + nextUrl.search;

    history.push(nextPath);
    if (!(match.url.startsWith(routeUrlFactory('catalogue')) || match.url.startsWith(routeUrlFactory('special')))) {
      history.push(routeUrlFactory('catalogue'));
    }
  }, [history, match.url, routeUrlFactory, searchFilter, setFilter]);

  const handleSearchOnEnter = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (k) => {
      if (k.key === 'Enter' || k.keyCode === 13 || k.which === 13) {
        handleSearch();
        setMobileSearchOpen(false);
      }
    },
    [handleSearch],
  );

  // reset search text real value when coming from another page w/o AppHeader remount
  useEffect(() => {
    setSearchFilter(initialState?.searchText || '');
  }, [initialState?.searchText]);

  return (
    <AppBar className={classes.root}>
      <div className={classes.toolbar}>
        <IconButton
          edge='start'
          className={clsx(classes.menuButton, isCatalogue && classes.menuButtonCatalogue)}
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.mainLogoWrapper}>
          <Link component={RouterLink} to={routeUrlFactory('homePage')} noWrap>
            <img src={`/${projectName}/mainLogo_small.png`} alt={`${projectName} logo`} className={classes.mainLogo} />
          </Link>
        </div>
        {/* Původní podmínka níže, nutno upravit dle designového zadání */}
        {/* {!isMobile && mobileSearchOpen && (  */}
        {!isBelowMedium && <NavigationLinks className={classes.navLinksWrapper} />}

        {(!isBelowMedium || mobileSearchOpen) && showFilter && (
          <div className={classes.search}>
            <TextField
              margin='dense'
              fullWidth
              label={t('app/globalSearchPlaceholder')}
              value={searchFilter}
              className={classes.textField}
              onChange={filterOnChange}
              onKeyUp={handleSearchOnEnter}
              inputProps={{
                type: 'search',
                enterkeyhint: 'done',
              }}
            />
            <SearchIcon color='primary' fontSize='default' onClick={handleSearch} className={classes.startIcon} />

            {searchFilter.length > 0 && (
              <IconButton onClick={handleResetSearchText} className={classes.endIcon}>
                <ClearIcon fontSize='small' />
              </IconButton>
            )}
          </div>
        )}
        <div className={clsx(classes.iconsWrapper)}>
          {isBelowMedium && showFilter && (
            <SearchMobile mobileSearchOpen={mobileSearchOpen} onClick={handleClick} className={classes.searchMobile} />
          )}
          {isAuthenticated && <MainNavigationContentItemProfile />}
          {isAuthenticated && <ShoppingCartAppBarAction color='inherit' />}
          <LanguageMenu />
          {isAuthenticated && (
            <Tooltip title={t('app/logout')}>
              <IconButton
                edge='end'
                color='inherit'
                onClick={() => {
                  setLogoutConfirm(true);
                }}
              >
                <LockIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <DialogConfirm
          open={logoutConfirm}
          onClose={() => {
            setLogoutConfirm(false);
          }}
          onConfirm={() => {
            history.push(routeUrlFactory('logout'));
          }}
          title={t('app/logout')}
        >
          <div>{t('app/logoutConfirmation')}</div>
        </DialogConfirm>
      </div>
    </AppBar>
  );
}

const SearchMobile: React.FC<{ className?: string; mobileSearchOpen: boolean; onClick: () => void }> = ({
  className,
  onClick,
  mobileSearchOpen,
}) => (
  <button className={className} onClick={onClick}>
    {!mobileSearchOpen ? <SearchIcon /> : <ClearIcon />}
  </button>
);
